@use "./reset.scss";
@use "./colors";

@import "./fonts/index.scss";
@import url("https://fonts.googleapis.com/css2?family=Fira+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  font-family: gill-sans-medium;
  color: colors.$primary-dark;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: silk-serif-bold;
}

p {
  line-height: 1.3;
}

*,
*:before,
*:after {
  -webkit-box-sizing: inherit;
  -moz-box-sizing: inherit;
  box-sizing: inherit;
}

.scroll-top-button {
  background-color: colors.$primary !important;
  outline: none;
  border: none;
  opacity: 0.5;
  transition: opacity 0.3s linear;

  &:hover {
    opacity: 1;
  }

  svg {
    fill: colors.$white !important;
    width: 20px;
  }
}
