@use "../../common/ui/assets/colors";

.contact {
  .info-box {
    color: colors.$black-4;
    text-align: center;
    box-shadow: 0 0 30px rgba(214, 215, 216, 0.3);
    padding: 20px 0 30px 0;
    background-color: colors.$white;

    i {
      font-size: 32px;
      color: colors.$primary;
      border-radius: 50%;
      padding: 8px;
      border: 2px dotted colors.$primary;
    }

    h3 {
      font-size: 20px;
      margin: 10px 0;
    }

    p {
      padding: 0;
      line-height: 24px;
      font-size: 14px;
      margin-bottom: 0;
    }
  }
}
