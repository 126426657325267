@use "../../common/ui/assets/colors";

.innovations {
  .container {
    border-top: 2px solid colors.$highlight;
    padding-bottom: 100px;

    &:last-child {
      padding-bottom: 0;
    }

    &:nth-child(2) {
      border: 0;
    }

    .title__info {
      padding: 0 50px 0 0;

      &.asufixMicro {
        padding: 100px 50px 0 0;
      }

      .subTitle {
        line-height: 1.5;
        font-size: 24px;
      }

      .title {
        font-size: 65px;
        margin-bottom: 50px;
        font-family: "silk-serif-medium";
      }
    }

    .video {
      padding: 0 0 0 50px;
    }

    .content {
      font-size: 35px;
      font-family: "gill-sans-medium";

      .logo {
        background-color: colors.$section-bg;
        margin: 0 12px;
        width: auto;

        img {
          max-width: 150px;
        }
      }
    }

    .compoundSamba,
    .asufixDel {
      div:last-child {
        margin-top: 200px;
      }

      p {
        font-size: 25px;
        margin-top: 10px;
      }

      img {
        width: 100%;
        height: 400px;
        object-fit: cover;
      }
    }
  }
}

@media (max-width: 992px) {
  .innovations {
    .container {
      .title__info {
        padding: 0 !important;

        &.asufixMicro {
          padding-top: 20px !important;
        }
      }

      .video {
        padding: 0;
      }

      > div {
        justify-content: center;

        .title__info {
          margin: 24px 0;
          text-align: center;
          order: 1;
          padding: 0;
          margin-bottom: 0;
        }
      }

      .content {
        font-size: 25px;
      }

      .compoundSamba,
      .asufixDel {
        div:last-child {
          margin-top: 50px;
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .innovations {
    .container {
      > div {
        .title__info {
          .title {
            font-size: 55px;
          }
        }
      }
    }
  }
}
