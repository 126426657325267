@use "../../../assets/colors";

.header {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: colors.$white;
  box-shadow: 0px 2px 15px rgba(0, 0, 0, 0.1);
  height: 86px;
  z-index: 10;
  transition: all 0.5s;
  font-family: "gill-sans-medium";

  .logo {
    img {
      max-width: 200px;
    }
  }

  &.fixed-top {
    height: 80px;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
  }

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .navbar ul {
      gap: 0 25px;
      display: flex;
      align-items: center;
      font-size: 18px;

      .mainItem {
        cursor: pointer;
        position: relative;
        text-transform: capitalize;

        a,
        & {
          color: colors.$primary-dark;
        }

        &:before {
          content: "";
          position: absolute;
          width: 100%;
          height: 2px;
          bottom: -6px;
          left: 0;
          background-color: colors.$primary;
          visibility: hidden;
          width: 0px;
          transition: all 0.3s ease-in-out 0s;
        }

        &.active,
        &:hover {
          .mainItem_link,
          & {
            color: colors.$primary;
          }

          &:before {
            visibility: visible;
            width: 100%;
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .navbar {
    display: none;
  }

  i {
    font-size: 20px;
    cursor: pointer;
  }
}
