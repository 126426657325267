@use "../../assets/colors";

.section {
  padding: 60px 0;
  overflow: hidden;
  background-color: colors.$white;

  &.section-bg {
    background-color: colors.$section-bg;
    color: colors.$primary-dark;
  }
}
