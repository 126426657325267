@use "../../assets/colors";

.section-title {
  text-align: center;
  padding-bottom: 30px;

  h2 {
    font-size: 15px;
    letter-spacing: 1px;
    padding: 8px 20px;
    margin: 0;
    color: colors.$primary;
    background-color: colors.$title-bg;
    display: inline-block;
    text-transform: uppercase;
    border-radius: 50px;
  }

  h3 {
    margin: 15px 0 0 0;
    font-size: 32px;
    text-transform: capitalize;
    font-family: "gill-sans-medium";

    span {
      color: colors.$primary;
    }
  }

  p {
    margin: 15px auto 0 auto;
    line-height: 1.5;
  }
}

@media (min-width: 1024px) {
  .section-title p {
    width: 50%;
  }
}
