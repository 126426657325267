@use "../../common/ui/assets/colors";

.about {
  .content {
    h3 {
      font-weight: 600;
      font-size: 26px;
      margin-bottom: 10px;
    }

    p {
      margin-bottom: 18px;
      line-height: 1.5;
      font-family: gill-sans-medium;
      font-size: 20px;
    }

    ul {
      list-style: none;
      padding: 0;
      margin-bottom: 16px;

      li {
        display: flex;
        align-items: flex-start;
        margin-bottom: 35px;
        &:first-child {
          margin-top: 35px;
        }
      }

      i {
        background: colors.$white;
        box-shadow: 0px 6px 15px rgba(16, 110, 234, 0.12);
        font-size: 24px;
        padding: 20px;
        margin-right: 15px;
        color: colors.$primary;
        border-radius: 50px;
      }

      h5 {
        font-size: 18px;
        color: colors.$black-5;
        font-weight: 500;
        margin-bottom: 8px;
      }

      p {
        font-size: 15px;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

@media (min-width: 992px) and (max-width: 1200px) {
  .about {
    .content {
      p {
        font-size: 16px;
      }
    }
  }
}
