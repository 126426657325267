@use "../../assets/colors";

.dropdown {
  position: relative;

  .dropdownToggle {
    display: flex;
    align-items: center;

    i {
      margin-left: 8px;
      font-size: 10px;
    }
  }

  .popover {
    position: absolute;
    top: calc(100% + 30px);
    left: 0;
    flex-direction: column;
    align-items: flex-start !important;
    background: #fff;
    box-shadow: 0px 0px 30px rgba(127, 137, 161, 0.25);
    transition: 0.3s;
    padding: 20px;
    min-width: 180px;
    min-height: 200px;
    gap: 20px 0 !important;
    visibility: hidden;
    transition: 0.3s;
    opacity: 0;
    z-index: 999;

    &.show {
      visibility: visible;
      top: calc(100% + 10px);
      opacity: 1;
    }

    .subItem {
      font-weight: 400;
      color: colors.$black-2 !important;
      line-height: 1.5em;

      &:hover {
        a,
        & {
          color: colors.$primary !important;
        }

        &:before {
          visibility: visible;
          width: 100%;
        }
      }
    }
  }
}
