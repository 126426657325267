// ---------------SILK SERIF------------------

@font-face {
  font-family: silk-serif-light;
  src: url(./silk-serif/Silk-Serif-Light.otf) format("truetype");
}

@font-face {
  font-family: silk-serif-extralight;
  src: url(./silk-serif/Silk-Serif-ExtraLight.otf) format("truetype");
}

@font-face {
  font-family: silk-serif-regular;
  src: url(./silk-serif/Silk-Serif-Regular.otf) format("truetype");
}

@font-face {
  font-family: silk-serif-medium;
  src: url(./silk-serif/Silk-Serif-Medium.otf) format("truetype");
}

@font-face {
  font-family: silk-serif-semiBold;
  src: url(./silk-serif/Silk-Serif-SemiBold.otf) format("truetype");
}

@font-face {
  font-family: silk-serif-bold;
  src: url(./silk-serif/Silk-Serif-Bold.otf) format("truetype");
}

@font-face {
  font-family: silk-serif-black;
  src: url(./silk-serif/Silk-Serif-Black.otf) format("truetype");
}

// ---------------GILL SANS------------------

@font-face {
  font-family: gill-sans-light;
  src: url(./gill-sans/Gill-Sans-Light.otf) format("truetype");
}

@font-face {
  font-family: gill-sans-regular;
  src: url(./gill-sans/Gill-Sans.otf) format("truetype");
}

@font-face {
  font-family: gill-sans-medium;
  src: url(./gill-sans/Gill-Sans-Medium.otf) format("truetype");
}

@font-face {
  font-family: gill-sans-bold;
  src: url(./gill-sans/Gill-Sans-Bold.otf) format("truetype");
}
