@use "../../assets/colors";

.wrapper {
  width: 95%;
  height: 85%;
  position: absolute;
  transform: translate(-50%, 5%);
  z-index: 100;

  i {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
    color: colors.$white;
  }

  .modal {
    position: absolute;
    background-color: colors.$white;
    padding: 20px;
    height: 100%;
    width: 100%;
    overflow: auto;

    &::-webkit-scrollbar {
      width: 5px;
    }

    &::-webkit-scrollbar-thumb {
      background: colors.$primary-dark;
      border-radius: 10px;
    }

    > ul {
      width: 100%;

      .navItem {
        font-family: "gill-sans-regular";

        &,
        .navItem_link {
          text-transform: capitalize;
          color: colors.$black-1;
          font-weight: 600;
          margin-bottom: 25px;
          cursor: pointer;
        }

        &.active,
        &:hover {
          &,
          a {
            color: colors.$primary;
          }
        }
      }
    }
  }
}
