@use "../../assets/colors";

.container {
  h4 {
    text-align: center;
    font-size: 32px;
    font-family: "gill-sans-medium";
  }

  .stage {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 2rem 0;
    margin: 0 -5%;
    overflow: hidden;

    .dot-flashing {
      position: relative;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: colors.$primary;
      color: colors.$primary;
      animation: dotFlashing 1s infinite linear alternate;
      animation-delay: 0.5s;
    }

    .dot-flashing::before,
    .dot-flashing::after {
      content: "";
      display: inline-block;
      position: absolute;
      top: 0;
    }

    .dot-flashing::before {
      left: -15px;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: colors.$primary;
      color: colors.$primary;
      animation: dotFlashing 1s infinite alternate;
      animation-delay: 0s;
    }

    .dot-flashing::after {
      left: 15px;
      width: 10px;
      height: 10px;
      border-radius: 5px;
      background-color: colors.$primary;
      color: colors.$primary;
      animation: dotFlashing 1s infinite alternate;
      animation-delay: 1s;
    }

    @keyframes dotFlashing {
      0% {
        background-color: colors.$primary;
      }
      50%,
      100% {
        background-color: colors.$white-2;
      }
    }
  }
}
