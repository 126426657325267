@use "../../common/ui/assets/colors";

.supplier {
  text-align: center;

  .wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;

    .supplier-box {
      display: flex;
      justify-content: center;
      align-items: center;

      img {
        max-width: 40%;
        transition: all 0.4s ease-in-out;
        display: inline-block;
        padding: 15px 0;

        &:hover {
          transform: scale(1.15);
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .wrapper {
    grid-template-columns: 1fr 1fr 1fr !important;
    gap: 0 3rem;
  }
}

@media (max-width: 768px) {
  .supplier {
    .wrapper {
      grid-template-columns: 1fr 1fr !important;

      img {
        max-width: 40%;
      }
    }
  }
}

@media (max-width: 400px) {
  .supplier {
    .wrapper {
      grid-template-columns: 1fr !important;

      img {
        max-width: 100%;
      }
    }
  }
}
