@use "../../assets/colors";

.accordion {
  position: relative;
  color: colors.$black-1 !important;

  .accordionToggle {
    display: flex;
    align-items: center;
    justify-content: space-between;

    i {
      color: colors.$black-1;
      font-size: 14px;
      margin-bottom: 0;
    }

    &:hover {
      i {
        color: colors.$primary;
      }
    }
  }

  .panel {
    display: flex;
    flex-direction: column;
    padding: 0 25px;
    gap: 25px 0;
    z-index: 99;
    opacity: 1;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.4s ease-out;

    &.show {
      max-height: 300px;
    }

    li {
      a {
        color: colors.$black-1 !important;
      }

      &:first-child {
        margin-top: 25px;
      }
    }
  }

  &:hover {
    li {
      color: colors.$black-1;
    }
  }
}
