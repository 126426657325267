@use "../../../assets/colors";

.topbar {
  background-color: colors.$primary;
  color: colors.$white;
  padding: 10px 0;

  .wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;

    .contact,
    .social-web {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    .contact {
      gap: 0 20px;

      a {
        color: colors.$white;
        text-decoration: none;

        &:hover {
          text-decoration: underline;
          text-decoration-thickness: 1px;
        }
      }

      svg {
        fill: colors.$white;
      }
    }
  }
}

@media (max-width: 768px) {
  .topbar {
    .wrapper {
      justify-content: center;
    }
  }
}
