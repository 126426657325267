@use "../../../assets/colors";

#footer {
  background: colors.$white;
  color: colors.$black-4;
  font-size: 16px;
  padding: 60px 0;
  font-family: "gill-sans-medium";

  .footer-contact {
    margin-bottom: 30px;

    .logo {
      font-size: 24px;
      margin: 0 0 15px 0;
      font-family: "silk-serif-bold";

      span {
        color: colors.$primary-dark;
      }
    }

    p {
      font-size: 14px;
      line-height: 24px;
      margin-bottom: 0;
    }
  }

  h4 {
    font-size: 18px;
    color: colors.$black-4;
    position: relative;
    padding-bottom: 12px;
    margin-bottom: 8px;
    font-family: "gill-sans-medium";
  }

  .footer-links {
    margin-bottom: 30px;

    ul {
      list-style: none;
      padding: 0;
      margin: 0;

      li {
        padding: 10px 0;
        display: flex;
        align-items: center;

        &:first-child {
          padding-top: 0;
        }

        i {
          padding-right: 10px;
          color: colors.$primary;
          font-size: 10px;
          line-height: 1;
        }

        p {
          transition: 0.3s;
          display: inline-block;
          line-height: 1;
          cursor: pointer;

          &:hover {
            text-decoration: none;
            color: colors.$primary;
          }
        }
      }
    }
  }

  .social-links p {
    font-size: 16px;
    display: inline-block;
    background: colors.$primary;
    color: colors.$white;
    line-height: 1;
    padding: 8px 0;
    margin-right: 4px;
    border-radius: 4px;
    text-align: center;
    width: 36px;
    height: 36px;
    transition: 0.3s;

    i {
      margin-top: 2px;
    }

    &:hover {
      background: #3b8af2;
      color: colors.$white;
      text-decoration: none;
    }
  }

  .copyright {
    text-align: center;
    float: left;

    strong {
      font-weight: 700;
    }
  }
}

@media (max-width: 768px) {
  #footer .copyright,
  #footer .credits {
    float: none;
    text-align: center;
    padding: 2px 0;
  }
}
