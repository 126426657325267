$primary: #106eea;
$primary-dark: #01153c;
$highlight: #53b947;

$title-bg: #e7f1fd;
$section-bg: #f6f9fe;

$white: #fff;
$white-1: #f6f6f6;
$white-2: #e2eefd;
$white-3: #eee;
$white-4: #ddd;

$black: #000;
$black-1: #111;
$black-2: #222;
$black-3: #333;
$black-4: #444;
$black-5: #555;

$inactive: rgba(255, 255, 255, 0.12);
$inactive-link: #3c3c3c;
$active-link: #6ba7f5;
