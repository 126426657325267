@use "../../../../common/ui/assets/colors";

.textile {
  .container {
    background: linear-gradient(rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0.4)),
      url("../../../../common/ui/assets/images/markets/textile.jpg");
    background-size: cover;
    position: relative;
    width: 100%;
    height: 500px;

    h1 {
      color: colors.$white-1;
      position: absolute;
      top: 50%;
      left: 50%;
      font-size: 72px;
      letter-spacing: 10px;
      transform: translate(-50%, -50%);
    }
  }

  .masonry {
    display: flex;
    gap: 1rem;

    .product-box {
      text-align: center;
      border: 1px solid colors.$white-2;
      padding: 30px 20px;
      min-height: 220px;
      background: colors.$white;
      transition: all ease-in-out 0.3s;

      h4 {
        margin-bottom: 15px;
        font-size: 24px;
        font-family: "gill-sans-medium";

        span {
          color: colors.$black-2;
          transition: ease-in-out 0.3s;
          text-transform: capitalize;
        }
      }

      p {
        line-height: 24px;
        font-size: 14px;
        margin-bottom: 0;
      }

      &:hover {
        border-color: colors.$white;
        box-shadow: 0px 0 25px 0 rgba(16, 110, 234, 0.1);

        svg {
          fill: colors.$primary;
        }

        h4 span {
          color: colors.$primary;
        }
      }

      .expandArrow {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 20px auto 0;
        cursor: pointer;

        svg {
          color: #1a3863;
        }
      }
    }
  }

  .certifications {
    width: 100%;
    margin-top: 120px;

    .title {
      width: 100%;
      background-color: colors.$primary-dark;
      color: colors.$white;
      padding: 48px 64px;
      font-size: 48px;
    }

    .desc {
      font-size: 24px;
      margin-top: 20px;
    }

    .logo {
      img {
        width: 200px;
      }
    }
  }
}

@media (max-width: 992px) {
  .textile {
    .container {
      height: 400px;

      h1 {
        font-size: 48px;
      }
    }
  }
}

@media (max-width: 768px) {
  .textile {
    .certifications {
      .title {
        padding: 32px 16px;
        font-size: 36px;
      }
    }
  }
}

@media (max-width: 400px) {
  .textile {
    .container {
      height: 200px;

      h1 {
        font-size: 32px;
      }
    }
  }
}
