@use "../../assets/colors";

.MuiStep-root {
  .Mui-active,
  .Mui-completed {
    .MuiStepConnector-line {
      background-image: linear-gradient(
        95deg,
        #88d969,
        #46cb18,
        #06a10b,
        #1d800e
      );
    }
  }

  .MuiStepConnector-alternativeLabel {
    top: 22px;

    .MuiStepConnector-line {
      height: 3px;
      border: 0;
      border-radius: 1px;
      background-color: #eaeaf0;
    }
  }
}

.MuiStepLabel-iconContainer {
  > div {
    background-color: #ccc;
    z-index: 1;
    color: #fff;
    width: 50px;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;

    &.active {
      background-image: linear-gradient(
        136deg,
        #88d969,
        #46cb18,
        #06a10b,
        #1d800e
      );
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.25);
    }

    &.completed {
      background-image: linear-gradient(
        136deg,
        #88d969,
        #46cb18,
        #06a10b,
        #1d800e
      );
    }
  }
}
