@use "../../common/ui/assets/colors";

.hero {
  width: 100%;
  height: 80vh;
  background: url("../../common/ui/assets/images/hero-bg.jpg") center center;
  background-size: cover;
  position: relative;

  &.scrolled-offset {
    margin-top: 70px;
  }

  &:before {
    content: "";
    background: colors.$inactive;
    position: absolute;
    bottom: 0;
    top: 0;
    left: 0;
    right: 0;
  }

  h1 {
    margin: 0;
    font-size: 48px;
    font-weight: 700;
    line-height: 56px;
    color: colors.$black-2;
    font-family: "Poppins", sans-serif;

    span {
      color: colors.$primary;
    }
  }

  &__desc {
    font-size: 24px;
    font-family: "gill-sans-medium";
    line-height: 1.3;
  }
}

@media (min-width: 1024px) {
  .hero {
    background-attachment: fixed;
  }
}

@media (max-width: 768px) {
  .hero {
    height: 100vh;
    h1 {
      font-size: 28px;
      line-height: 36px;
    }
    h2 {
      font-size: 18px;
      margin-bottom: 30px;
    }
  }
}

@media (max-height: 500px) {
  .hero {
    height: 120vh;
  }
}
